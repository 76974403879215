import React, { useState } from 'react';
import Layout from "../components/layout"
import { graphql, withPrefix } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
//import moment from "moment"
import HubspotContactForm from '../components/HubspotContactForm';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { IconContext } from "@react-icons/all-files";
import { FaUserLock } from "@react-icons/all-files/fa/FaUserLock";
import { FaMapMarkedAlt } from "@react-icons/all-files/fa/FaMapMarkedAlt";
import { FaBalanceScale } from "@react-icons/all-files/fa/FaBalanceScale";
import { AiFillPlusCircle } from "@react-icons/all-files/ai/AiFillPlusCircle";
import { AiFillMinusCircle } from "@react-icons/all-files/ai/AiFillMinusCircle";
import { FaExternalLinkAlt } from "@react-icons/all-files/fa/FaExternalLinkAlt";
import { FaArrowAltCircleDown } from "@react-icons/all-files/fa/FaArrowAltCircleDown";
import { FaHeadset } from "@react-icons/all-files/fa/FaHeadset";
import { FaEuroSign } from "@react-icons/all-files/fa/FaEuroSign";
import { FaUserCog } from "@react-icons/all-files/fa/FaUserCog";
import { FaYenSign } from "@react-icons/all-files/fa/FaYenSign";
import { FaAngleDown } from "@react-icons/all-files/fa/FaAngleDown";

const IndexPage = ({ data }) => {
  //const posts = data.allRestApiDevTop.edges;
  const documentPosts = data.contentfulDocumentsManagement.posts;
  const faqPosts = data.contentfulFaqManagement;
  const prices = data.contentfulPriceAndPlans;
  const [isThumbnail, setIsThumbnail] = useState(true);

  const [checkedContent, setCheckedContent] = useState({})
  const handleChange = (e) => {
    let isChecked = e.target.checked;

    setCheckedContent({
      ...checkedContent,
      [e.target.name]: e.target.name === "plans" ? e.target.id + isChecked : isChecked
    });
  }

  return (
  <Layout>
    <div className="ast-section section-top">
      <div
        className="elementor-background-overlay"
        style={{ backgroundImage: `url(${withPrefix('/images/cookiebot-header_light-grey.jpg')})`}}
      ></div>
      <div className="ast-container">
        <div className="flex-row section-top-row">
          <div className="flex-column-6 section-top-column">

            <p className="cm-maincopy cm-maincopy">Cookiebotは、GDPR*1 、CCPA*2 や LGPD*3 等のePrivacy法に準拠したCookie同意バナーを簡単に生成・埋め込みできるサービスです。</p>
            <div
              className="cm-header-certificate" 
              style={{marginBottom: '10px', display: 'inline-block'}}>
              <div className="header-cert-wrapper">
              <img
                src="../images/Partner-Badges-Certified.png"
                alt="cookiebot partner certification"
                className="header-cert"
                width={125}
                style={{float: 'left'}}
              />
              </div>
              <div
                className="cm-header-text"   
                style={{
                  float: 'left',
                  display: 'inline-block',
                  width: 'calc( 100% - 150px)',
                  paddingLeft: '20px',
                  lineHeight: '1.6'
                }}>
                  クラスメソッド ヨーロッパは Usercentrics 社とのパートナー契約を締結し、<strong>日本語での導入サポート</strong>を提供しています。
              </div>
            </div>
            <p>
              *1 EU一般データ保護規則　*2 カリフォルニア州消費者プライバシー法　*3 ブラジル一般データ保護法
            </p>
            <div className="button-top">
              <AnchorLink href="#cookiebot" offset="0" className="button-general">
                <span>もっと詳しく</span>
                <IconContext.Provider value={{ className: "icon-video-arrow" }}>
                  <FaAngleDown />
                </IconContext.Provider>
              </AnchorLink>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="ast-section section-video" id="cookiebot">
      <div className="ast-container">
        <div className="flex-row section-video-row">
          <div className="flex-column-6 section-video-column">
            <div className="video">
              {isThumbnail ? (
                <a onClick={() => setIsThumbnail(false)} className="button-play">
                <StaticImage
                  src="../images/youtube-thumbnail.png"
                  alt="Cookiebot Youtube Thumbnail"
                />
                </a>
              ) : (
                <iframe
                  src="https://www.youtube.com/embed/t2YDJrG22Ek?autoplay=1"
                  title="cookiebot"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  frameBorder="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                />
              )}
             </div>
          </div>
          <div className="flex-column-6 section-video-column section-video-column-right">
            <h2>ユーザーのプライバシーに配慮したサイト分析や広告配信が可能です。</h2>
            <p>Googleの同意モードに対応し、訪問者から同意がない場合でもGoogle AnalyticsやGoogle Adsを配信することができます。</p>
            <div className="section-video-icons">
              <StaticImage
                src="../images/google-tag-manager-logo.png"
                alt="google-tag-manager-logo.png"
                width={50}
                height={50}
              />
              <StaticImage
                src="../images/google-analytics-logo-150x150.png"
                alt="google-analytics-logo-150x150.png"
                width={50}
                height={50}
              />
              <StaticImage
                src="../images/googleads.png"
                alt="googleads.png"
                width={50}
                height={50}
              />
              </div>
            <div className="button-video-trial">
              <AnchorLink href="#contact" offset="0">
                <span>2週間無料トライアル</span>
                <IconContext.Provider value={{ className: "icon-video-arrow" }}>
                  <FaAngleDown />
                </IconContext.Provider>
              </AnchorLink>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="ast-section section-feature">
      <div className="ast-container">
        <div className="flex-row section-feature-row">
          <div className="flex-column-4 section-feature-column">
            <div className="elementor-image-box-img">
              <StaticImage
                src="../images/cookie-consent.png"
                alt="classmethod cookiebot consent"
                width={37}
                height={37}
              />
            </div>
            <div className="elementor-image-box-content">
              <h3 className="elementor-image-box-title">eプライバシーに即したユーザー同意</h3>
              <p className="elementor-image-box-description"><span>GDPR, CCPA 及び LGPD に準拠したユーザー同意バナーを、お好きな言語で自由にカスタマイズできます。</span></p>
            </div>
          </div>
          <div className="flex-column-4 section-feature-column">
            <div className="elementor-image-box-img">
              <StaticImage
                src="../images/cookiemonitoring.png"
                alt="classmethod cookiebot monitor"
                width={37}
                height={37}
              />
            </div>
            <div className="elementor-image-box-content">
              <h3 className="elementor-image-box-title">Cookie利用状況のモニタリング</h3>
              <p className="elementor-image-box-description"><span>Web サイトを自動スキャンして、現在使われているCookieやトラッカーの可視化が可能です。</span></p>
            </div>
          </div>
          <div className="flex-column-4 section-feature-column">
            <div className="elementor-image-box-img">
              <StaticImage
                src="../images/businessplans.png"
                alt="classmethod business plan"
                width={37}
                height={37}
              />
            </div>
            <div className="elementor-image-box-content">
              <h3 className="elementor-image-box-title">Googleサービス始め豊富なサードパーティ連携</h3>
              <p className="elementor-image-box-description"><span>gtag.js、Google Tag ManagerやWordpressプラグインなどサードパーティツールへの導入も非常に簡単です。</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="ast-section section-prices" id="packages">
      <div className="ast-container">
        <h2>料金プラン</h2>
        <div className="flex-row">
          <div className="flex-column-8 plans-wrapper">
            <div className="plans-block">
              <h3>Usercentrics 直販契約</h3>
              <div className="ribbon18-content">
                <span className="ribbon18">オススメ</span>
              </div>
              <div className="plans-block-lines">
                <p>
                  <IconContext.Provider value={{ className: "icon-plans-line" }}>
                    <FaHeadset />
                  </IconContext.Provider>
                  <span>無料導入サポート</span>
                </p>
                <p>
                  <IconContext.Provider value={{ className: "icon-plans-line" }}>
                    <FaEuroSign />
                  </IconContext.Provider>
                  {/*<span><strong>ユーロ</strong>建て<strong>クレジットカード</strong>払い</span>*/}
                  <span>ユーロ/ドルでのクレジットカード払い/PayPal（月額/年額）</span>
                </p>
                <p>
                  <IconContext.Provider value={{ className: "icon-plans-line" }}>
                    <FaUserCog />
                  </IconContext.Provider>
                  <span>管理コンソールの<strong>アクセス有</strong></span>
                </p>
              </div>
              <div className="button-prices-wrapper">
                <div
                  className={`button-prices ${checkedContent.plans === 'planEurtrue' ? 'active' : ''}`}
                >
                  <input
                    type="radio"
                    name="plans"
                    id="planEur"
                    onChange={handleChange}
                    className="checkbox-plans"
                  />
                  <label htmlFor="planEur">
                    <div className="button-prices-inner">
                      <span className="title-prices">料金表 (ユーロ €)</span>
                      <IconContext.Provider value={{ className: "icon-plans" }}>
                        <FaArrowAltCircleDown />
                      </IconContext.Provider>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-column-4 plans-wrapper">
            <div className="plans-block">
              <h3 style={{fontSize: "1.4rem"}}><s>Classmethod 再販契約</s></h3>
                <p style={{ color: "rgb(232 25 25)"}}>現在受付を停止中です。</p>
              <div className="plans-block-lines">
                <p style={{ fontSize: "1rem" }}>
                  <IconContext.Provider value={{ className: "icon-plans-line" }}>
                    <FaHeadset />
                  </IconContext.Provider>
                  <span><s>無料導入サポート</s></span>
                </p>
                <p style={{ fontSize: "1rem" }}>
                  <IconContext.Provider value={{ className: "icon-plans-line" }}>
                    <FaYenSign />
                  </IconContext.Provider>
                  <span><s>日本円建て銀行振込（年額契約のみ）</s></span>
                  </p>
                <p style={{ fontSize: "1rem" }}>
                  <IconContext.Provider value={{ className: "icon-plans-line" }}>
                    <FaUserCog />
                  </IconContext.Provider>
                  <span><s>管理コンソールのアクセス無</s></span>
                </p>
              </div>
              <div className="button-prices-wrapper">
                <div
                  className={`button-prices ${checkedContent.plans === 'planJpytrue' ? 'active' : ''}`}
                  style={{ cursor: "not-allowed", backgroundColor: "#f0f0f0" }}
                >
                  <input
                    type="radio"
                    name="plans"
                    id="planJpy"
                    //onChange={handleChange}
                    className="checkbox-plans"
                    disabled
                  />
                  <label htmlFor="planJpy">
                    <div className="button-prices-inner">
                      <span className="title-prices">料金表 (日本円 ￥)</span>
                      <IconContext.Provider value={{ className: "icon-plans" }}>
                        <FaArrowAltCircleDown />
                      </IconContext.Provider>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row flex-row-prices">
          <div className="flex-column-4 prices-wrapper">
            <div className="prices-block">
              <h3>スモール S</h3>
              <div className="eael-pricing-tag">
                {checkedContent.plans === "planJpytrue"
                  ? <>
                    <span className="price-price"><span className="price-currency">¥</span>{Number(prices.agentPriceS).toLocaleString()}</span>
                    <span className="price-period">/ ドメイン毎・<b style={{color:"#3AA8EF"}}>年額</b></span>
                    </>
                  : <>
                    <span className="price-price"><span className="price-currency">€</span>{prices.directPriceS}</span>
                    <span className="price-period">/ ドメイン毎・<b>月額</b></span>
                    </>
                 }
              </div>
              <p>350サブページ未満のプラン</p>
              <a href="https://manage.cookiebot.com/en/signup?rid=CLASS" target="_blank" rel="noreferrer" className="eael-pricing-button button-general">
                本サイトで登録
                <IconContext.Provider value={{ className: "icon-external-link" }}>
                  <FaExternalLinkAlt />
                </IconContext.Provider>
              </a>
            </div>
          </div>
          <div className="flex-column-4 prices-wrapper">
            <div className="prices-block">
              <h3>ミディアム M</h3>
              <div className="eael-pricing-tag">
                {checkedContent.plans === "planJpytrue"
                  ? <>
                    <span className="price-price"><span className="price-currency">¥</span>{Number(prices.agentPriceM).toLocaleString()}</span>
                    <span className="price-period">/ ドメイン毎・<b style={{color:"#3AA8EF"}}>年額</b></span>
                    </>
                  : <>
                    <span className="price-price"><span className="price-currency">€</span>{prices.directPriceM}</span>
                    <span className="price-period">/ ドメイン毎・<b>月額</b></span>
                    </>
                 }
              </div>
              <p>3,500サブページ未満のプラン</p>
              <a href="https://manage.cookiebot.com/en/signup?rid=CLASS" target="_blank" rel="noreferrer" className="eael-pricing-button button-general">
                本サイトで登録
                <IconContext.Provider value={{ className: "icon-external-link" }}>
                  <FaExternalLinkAlt />
                </IconContext.Provider>
              </a>
            </div>
          </div>
          <div className="flex-column-4 prices-wrapper">
            <div className="prices-block">
              <h3>ラージ L</h3>
              <div className="eael-pricing-tag">
                {checkedContent.plans === "planJpytrue"
                  ? <>
                    <span className="price-price"><span className="price-currency">¥</span>{Number(prices.agentPriceL).toLocaleString()}</span>
                    <span className="price-period">/ ドメイン毎・<b style={{color:"#3AA8EF"}}>年額</b></span>
                    </>
                  : <>
                    <span className="price-price"><span className="price-currency">€</span>{prices.directPriceL}</span>
                    <span className="price-period">/ ドメイン毎・<b>月額</b></span>
                    </>
                 }
              </div>
              <p>3,500サブページ以上のプラン</p>
              <a href="https://manage.cookiebot.com/en/signup?rid=CLASS" target="_blank" rel="noreferrer" className="eael-pricing-button button-general">
                本サイトで登録
                <IconContext.Provider value={{ className: "icon-external-link" }}>
                  <FaExternalLinkAlt />
                </IconContext.Provider>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="ast-section section-options">
      <div className="ast-container">
        <h2>追加オプション</h2>
        <div className="flex-row">
          <div className="flex-column-4 options-wrapper">
            <div className="options-block">  
              <div className="img-options-wrapper">
                <div className="img-options-bg">
                  <IconContext.Provider value={{ className: "icon-options icon-option-lock", size: "1em", color: "white" }}>
                    <FaUserLock />
                  </IconContext.Provider>
                </div>
              </div>
              <h3>データストア暗号化</h3>
              <div className="options-block-overlay">
                <div className="options-block-overlay__description">
                  Amazon CloudFront/Lambda@Edgeを使用し、アクセス元を判別して特定のオリジンサーバーやデータストアに振り分け、 さらにフォームのフィールドレベル暗号化を実装します。 欧州内での恒常的な個人データハンドリングを高いコンプライアンスとセキュリティースタンダードで実行できます。
                </div>
                <AnchorLink href="#contact" offset="0">INQUIRE</AnchorLink>
              </div>
            </div>
          </div>
          <div className="flex-column-4 options-wrapper">
            <div className="options-block">  
              <div className="img-options-wrapper">
                <div className="img-options-bg">
                  <IconContext.Provider value={{ className: "icon-options icon-option-lock", size: "1em", color: "white" }}>
                    <FaMapMarkedAlt />
                  </IconContext.Provider>
                </div>
              </div>
              <h3>地域別アクセス制限</h3>
              <div className="options-block-overlay">
                <div className="options-block-overlay__description">
                  Amazon CloudFrontを既存のWebサイトを変更せずにスナップオン導入します。地域別にサイトアクセスを制限、遮断することでGDPRのクレームリスクを根本的に排除します。非AWSのバックエンドであっても、定額の導入費用とクラスメソッドの大幅な割引を適用した使用料金で最短1日で設定可能です。
                </div>
                <AnchorLink href="#contact" offset="0">INQUIRE</AnchorLink>
              </div>
            </div>
          </div>
          <div className="flex-column-4 options-wrapper">
            <div className="options-block">  
              <div className="img-options-wrapper">
                <div className="img-options-bg">
                  <IconContext.Provider value={{ className: "icon-options icon-option-lock", size: "1em", color: "white" }}>
                    <FaBalanceScale />
                  </IconContext.Provider>
                </div>
              </div>
              <h3>EU代理人サービス</h3>
              <div className="options-block-overlay">
                <div className="options-block-overlay__description">
                  欧州にウェブサイトを解放している場合、原則としてEU代理人の選出が必要です。GDPRが求めるEU代理人サービスを規模別の定額パッケージにてクラスメソッド ヨーロッパが提供します。Google Analytics や Firebase コンソール上で指定するEU/EEA代理人に、選出した代理人名義の使用が可能です
                </div>
                <AnchorLink href="#contact" offset="0">INQUIRE</AnchorLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="ast-section" id="faq">
      <div className="ast-container">
        <h2>よくあるご質問</h2>
        <div className="ast-flex post-faq-wrapper ast-flex-start">
          <div className="row-faq row-faq-1">
            <h3>契約に関するご質問</h3>
            {faqPosts.contractualPosts && faqPosts.contractualPosts.map( post => {
              return (
                <div className="post-faq" key={post.id}>
                  <input
                    type="checkbox"
                    name={post.id}
                    id={post.id}
                    onChange={handleChange}
                    className="checkbox-faq"
                  />
                  <label htmlFor={post.id}>
                    <span className="icon-faq-plus-wrapper">
                    <IconContext.Provider value={{ className: "icon-faq-plus" }}>
                    {checkedContent[post.id] 
                      ? <AiFillMinusCircle />
                      : <AiFillPlusCircle />
                    }
                    </IconContext.Provider>
                    </span>
                    <span className="title-faq">{post.title}</span>
                  </label>
                  {checkedContent[post.id] &&
                    (<div className="post-faq-body" dangerouslySetInnerHTML={{__html: post.content.childMarkdownRemark.html}} />)
                  }
                 
                </div>
              )
            })}
          </div>
          <div className="row-faq row-faq-2">
            <h3>技術的なご質問</h3>
            {faqPosts.technicalPosts && faqPosts.technicalPosts.map( post => {
              return (
                <div className="post-faq" key={post.id}>
                  <input
                    type="checkbox"
                    name={post.id}
                    id={post.id}
                    onChange={handleChange}
                    className="checkbox-faq"
                  />
                  <label htmlFor={post.id}>
                    <span className="icon-faq-plus-wrapper">
                    <IconContext.Provider value={{ className: "icon-faq-plus" }}>
                    {checkedContent[post.id] 
                      ? <AiFillMinusCircle />
                      : <AiFillPlusCircle />
                    }
                    </IconContext.Provider>
                    </span>
                    <span className="title-faq">{post.title}</span>
                  </label>
                  {checkedContent[post.id] &&
                    (<div className="post-faq-body" dangerouslySetInnerHTML={{__html: post.content.childMarkdownRemark.html}} />)
                  }
                 
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>

    <div className="ast-section section-hbsform">
      <div className="ast-container">
        <h2 id="contact">お問い合せ</h2>
        <div className="section-hbsform-content">
          <HubspotContactForm />
        </div>
      </div>
    </div>

    <div className="ast-section section-documentation" id="documentation">
      <div className="ast-container">
        <h2>利用ガイド・マニュアル</h2>
        <p className="sub-description">契約会員様のみ閲覧いただけます。</p>
        <div className="ast-flex post-documents-wrapper">
          {documentPosts && documentPosts.map( post => {
            return (
              <div className="post-document" key={post.slug}>
                <a href={`https://manual.cookiebot.jp/post/${post.slug}`} offset="0" target="_blank" rel="noreferrer">
                  <span>{post.title}</span>
                  <IconContext.Provider value={{ className: "icon-document-link" }}>
                    <FaExternalLinkAlt />
                  </IconContext.Provider>
                </a>
              </div>
            )
          })}
        </div>
      </div>
    </div>

    {/*
    <div className="ast-section section-devio" id="blog">
      <div className="ast-container">
        <h2>ブログ</h2>
        <div className="flex-row">
        {posts && posts.map(({ node: post }) => {
          return (
            <div className="flex-column-4 post-devio-item-wrapper" key={post.url}>
              <a href={post.url} target="_blank" rel="noreferrer" className="post-basic-item">
                <div className="post-basic-item-image">
                  {post.thumbnail &&
                    <img src={post.thumbnail} alt={post.title} loading="lazy" />
                  }
                </div>
                <div className="post-basic-item-content">
                  <h3>{post.title}</h3>
                  <div className="post-basic-postedate">
                    {moment(post.published).format(`YYYY-MM-DD HH:mm`)}
                  </div>
                </div>
              </a>
            </div>
          )
        })}
        </div>
      </div>
    </div>
    */}

  </Layout>
  )
}

export default IndexPage

export function Head() {
  return (
  <>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap" rel="stylesheet"></link>
  </>
  )
}

export const query = graphql`
  query QueryTop {
    contentfulPriceAndPlans(contentful_id: {eq: "79c71YTkNMV4Uf9EZlrMtv"}) {
        directPriceL
        directPriceM
        directPriceS
        agentPriceS
        agentPriceM
        agentPriceL
      }
    contentfulDocumentsManagement(contentful_id: {eq: "1HytkvFFWEN9TV4Zy87n2B"}) {
      posts {
        title
        slug
      }
    }
    contentfulFaqManagement(contentful_id: {eq: "2oYfjntH8EKpiPy3oEPKW4"}) {
        contractualPosts {
          id
          title
          content {
            childMarkdownRemark {
              html
            }
          }
        }
        technicalPosts {
          id
          title
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
  }
`;
