import React, { useState } from 'react';
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import { Cross as Hamburger } from 'hamburger-react'
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { IconContext } from "@react-icons/all-files";
import { FaExternalLinkAlt } from "@react-icons/all-files/fa/FaExternalLinkAlt";

const Header = ({ siteTitle }) => {
  const [isOpen, setOpen] = useState(false)

  return (
  <header className="main-header-bar-wrap">
    <div className="main-header-bar">
      <div className="ast-container ast-container-header">
        <div className="ast-flex main-header-container">
          <Link
           to="/"
           className="header-logo-wrapper"
          >
           <StaticImage
                src="../images/2025-cookiebot-site-logo.png"
             alt="cookiebot logo"
             className="header-logo"
             width={420}
           />
          </Link>
          <div className="header-menu-links">
            <div className="hamburger-react-wrapper"><Hamburger toggled={isOpen} toggle={setOpen} size={26} /></div>
            <nav id="nav-mb-links" className={isOpen ? "active" : "inactive"} >
              <ul className="main-header-menu ast-justify-content-flex-end ast-flex">
                {/*<li className="menu-item"><Link to="/">Home</Link></li>*/}
                <li className="menu-item"><AnchorLink href="#cookiebot" offset="0">Cookiebot</AnchorLink></li>
                <li className="menu-item"><AnchorLink href="#packages" offset="0">パッケージ</AnchorLink></li>
                <li className="menu-item"><AnchorLink href="#faq" offset="0">よくあるご質問</AnchorLink></li>
                <li className="menu-item"><AnchorLink href="#documentation" offset="0">利用ガイド</AnchorLink></li>
                <li className="menu-item">
                  <a href="https://dev.classmethod.jp/tags/cookiebot/" target="_blank" rel="noopener noreferrer">
                    <span style={{marginRight: "4px"}}>ブログ</span>
                      <IconContext.Provider value={{ className: "icon-document-link" }} >
                        <FaExternalLinkAlt style={{ fontSize: "0.8rem" }} />
                    </IconContext.Provider>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </header>
)}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
